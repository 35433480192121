<template>
	<ion-page>
		<Header currentPage="for-teachers"/>
		<ion-content>
			<div>
				<div>
					<div class="container guide-wrapper">
						<div>
							<h1>Návod na používanie OpenAcademy</h1>
							<div>
								<div class="superlesson-wrapper noBorder">
									<h3>
										<b>1. Úvodná obrazovka</b>
									</h3>
									<div class="lessons-wrapper">
										<div class="lesson-wrapper">
											<h4>Témy</h4>
											<div>
												<p>
													V sekcii "Témy" nájdete všetky dostupné témy. Viete
													si tému vyhľadať alebo zvoliť tému kliknutím na ňu. Po
													kliknutí na "Prejsť na všetky témy" v strednej časti domovskej stránky viete
													vyhľadávať a filtrovať všetky témy, o ktoré máte
													záujem. Pod filtrom nájdete záložky jednotlivých tém,
													ktoré sú v OpenAcademy zatiaľ dostupné (napr.
													HybridLab). Tie budú postupne pribúdať.
												</p>
											</div>
											<div class="video-wrapper">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="superlesson-wrapper">
									<h3><b>2. Téma</b></h3>
									<div class="lessons-wrapper">
										<div class="lesson-wrapper">
											<h4>Mini projekt</h4>
											<div>
												<p>
													Mini projekt predstavuje hlavnú myšlienku pre súhrnnú časť
													danej témy. Mini projekty pomahajú vytvoriť štruktúru danej téme
													a pomôže študentom uceliť si dané úlohy a mať hotový výsledok po jeho dokončení.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="superlesson-wrapper">
									<h3>
										<b>3. Užívateľské funkcie</b>
									</h3>
									<div class="lessons-wrapper">
										<div class="lesson-wrapper">
											<h4>Uložené (Bookmark)</h4>
											<div>
												<p>
													Študent má možnosť uložiť si mini projekt, úlohu alebo aj konkrétne video pomocou ikonky Uložiť <ion-icon class="size-l" :icon="bookmarksOutline" /> (bookmark).
													Uložené veci si študent môže pozrieť vo svojom profile.
												</p>
											</div>
										</div>
										<div class="lesson-wrapper">
											<h4>Dokončené (completed)</h4>
											<div>
												<p>
													Študent má možnosť označiť si mini projekt alebo úlohu ako dokončenú, pomocou ikonky Dokončené <ion-icon class="size-l" :icon="checkmarkDoneCircleOutline" style="font-size: 16px;" />(completed).
													Po tom ako študent zaznačí úlohu ako hotovú, vie si od učiteľa vyžiadať overenie tlačidlom <ion-icon class="size-l" :icon="schoolOutline" />.
													Dokončené veci si študent môže pozrieť vo svojom profile.
												</p>
											</div>
										</div>
										<div class="lesson-wrapper">
											<h4>Páčí sa mi (Likenuté)</h4>
											<div>
												<p>
													Študent má možnosť označiť video ako likenuté, pomocou ikonky Páči sa mi <ion-icon class="size-l" :icon="heart"/> (like).
													Likenuté veci si študent môže pozrieť vo svojom profile.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</ion-content>
	</ion-page>
</template>
<script>
import { bookmarksOutline, checkmarkDoneCircleOutline, schoolOutline, heart } from "ionicons/icons"


import Footer from "./components/Footer.vue"
import Header from './components/Header.vue'


export default {
	components: { Footer, Header },
	data() {
		return {
			bookmarksOutline,
			checkmarkDoneCircleOutline,
			schoolOutline,
			heart
		}
	},
}
</script>
<style lang="scss" scoped>
.guide-wrapper {
    padding: 0 30px 0 30px;
}
//ble
h1 {
  line-height: 55px;
  font-size: 45px;
  font-weight: 900 !important;
  margin-bottom: 20px;
}
.links {
  width: 300px;
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  margin-right: 17px;

  * {
    cursor: pointer;
  }

  .active {
    border-bottom: 3px solid #4632c1;
    color: #4632c1;
  }

  @media only screen and (max-width: 860px) {
    width: 150px;
    font-size: 12px;
  }
}

.logo {
  font-size: 30px;
  font-weight: 600;
  display: none;
}
.brand-wrapper {
  @media only screen and (max-width: 510px) {
    display: none;
  }
  display: flex;
  align-items: center;
  padding: 20px;

  * {
    margin-right: 20px;
  }

  img {
    height: 40px;
    @media only screen and (max-width: 630px) {
      height: 20px;
    }
  }

  img:hover {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 100;
    color: #505050;
  }

  .vertical-line {
    width: 1px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
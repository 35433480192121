<template>
	<ion-header>
		<ion-toolbar>
			<div class="brand-wrapper">
				<img src="@/assets/images/openacademy.svg" alt="open academy logo" @click="$router.push('/')">
				<div class="vertical-line partner-logos"></div>
				<p class="partner-logos">POWERED BY</p>
				<img class="partner-logos" :src="require('@/assets/images/ferova-nadacia.jpg')" @click="openUrl('https://spolocnost.o2.sk/ferova-nadacia')">
				<img class="partner-logos" :src="require('@/assets/images/SK-NIC2020.png')" @click="openUrl('https://sk-nic.sk/')">
			</div>
			<div class="links" slot="end">
				<span :class="{active: currentPage == 'homepage'}" @click="$router.push({name: 'homepage'})">
					<ion-icon v-if="isSmallScreen" :icon="homeOutline"></ion-icon>
					<span v-else>Úvod</span>
				</span>
				<span :class="{active: currentPage == 'about-us'}" @click="$router.push({name: 'about-us'})">
					<ion-icon v-if="isSmallScreen" :icon="peopleCircleOutline"></ion-icon>
					<span v-else>O nás</span>
				</span>
				<span :class="{active: currentPage == 'for-teachers'}" @click="$router.push({name: 'for-teachers'})">
					<ion-icon v-if="isSmallScreen" :icon="schoolOutline"></ion-icon>
					<span v-else>Pre učiteľov</span>	
				</span>
				<span @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<ion-icon v-if="isSmallScreen" :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
					<span v-else>{{ isLoggedIn ? "Profil" : "Prihlásiť sa" }}</span>	
				</span>
			</div>

			
		</ion-toolbar>
	</ion-header>
</template>

<script>
import { mapGetters } from 'vuex'
import { personCircleOutline, logInOutline, homeOutline, peopleCircleOutline, schoolOutline } from 'ionicons/icons'


export default {
	props: {
		currentPage: {type: String, required: true}
	},
	data() {
		return {
			personCircleOutline,
			logInOutline,
			homeOutline,
			peopleCircleOutline,
			schoolOutline,
			isSmallScreen: false
		}
	},
	created() {
		this.watchScreenSize()
	},
	methods: {
		openUrl(url) {
			window.open(url)
		},
		watchScreenSize() {
			this.isSmallScreen = (window.innerWidth < 1051)
			window.addEventListener('resize', () => {
				this.isSmallScreen = (window.innerWidth < 1051)
			})
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		]),
	}
}
</script>

<style lang="scss">
.links {
    width: 300px;
    display: flex;
    justify-content: space-around;
    font-weight: 500;
    margin-right: 17px;
    
    * {
        cursor: pointer;
    }

    .active {
        border-bottom: 3px solid #4632c1;
        color: #4632c1;
    }

    @media only screen and (max-width:1050px) {
        width: 150px;
		font-size: 24px;
    }
}

.logo {
    font-size: 30px;
    font-weight: 600;
    display: none;
}
.brand-wrapper {
    @media only screen and (max-width:600px) {
        .partner-logos {
			display: none;
		}
    }
		display: flex;
		align-items: center;
        padding: 16px;

		* {
			margin-right: 20px;
		}

		img {
			height: 40px;
            @media only screen and (max-width:630px) {
                height: 30px;
            }
		}

		img:hover {
			cursor: pointer;
		}

		p {
			margin-bottom: 0;
			font-size: 13px;
			font-weight: 100;
			color: #505050;
		}

		.vertical-line {
			width: 1px;
			height: 45px;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
.web-version-2 {
	@media only screen and (max-width:1050px) {
        display: none !important;
    }
}
.user-wrapper {
	ion-icon {
		width: 30px!important; 
		height: 30px!important; 
	}
}
</style>

<template>
	<div class="footer-wrapper">
		<div class="footer-content">
			<div class="newsletter-wrapper">
				<b-form @submit.prevent='newsletterRegistration()' class='my-4'>
					<h5>PRIHLÁSENIE DO NEWSLETTRA</h5>
					<b-alert :show="!!emailAlert.text"  :variant="emailAlert.type">{{emailAlert.text}}</b-alert>
					<b-form-checkbox v-model="gdpr" value="true" unchecked-value="false" class="mb-2">Súhlasím <a href="/pdf/podmienky-spracovania-osobnych-udajov.pdf" target="_blank" class="highlight">s podmienkami</a> spracovania osobných údajov</b-form-checkbox>
					<div class="input-button-wrapper">
						<input type="email" v-model='email' placeholder='Zadajte svoj e-mail'>
						<button type='submit'>Odoslať</button>
					</div>
				</b-form>
			</div>
			<div class="contact-info-flex-wrapper">
				<div class="contact-info-wrapper">
					<p><b>OpenLab</b></p>
					<a href="https://www.google.com/maps/place/WEZEO/@48.1365094,17.1069547,19z/data=!3m1!4b1!4m5!3m4!1s0x476c89426c18caa7:0x613b1fd617e95754!8m2!3d48.1365085!4d17.1075019" target="_blank">Viedenská cesta 257</a>
					<a href="https://www.google.com/maps/place/WEZEO/@48.1365094,17.1069547,19z/data=!3m1!4b1!4m5!3m4!1s0x476c89426c18caa7:0x613b1fd617e95754!8m2!3d48.1365085!4d17.1075019" target="_blank">851 01 Bratislava</a>
				</div>
				<div class="contact-info-wrapper">
					<p><b>Support</b></p>
					<a href="mailto:info@openlab.sk">info@openlab.sk</a>
				</div>
			</div>
			<div class="created-by-wrapper" @click='window.open("https://www.wezeo.com/sk")'>
				<p>Created by</p>
				<img src='@/assets/images/wezeo_logo.png' alt="wezeo logo">
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Footer',
	data() {
		return {
			window: window,
			email: '',
			gdpr: false,
			emailAlert: {
				type: '',
				text: ''
			}
		}
	},
	methods: {
		newsletterRegistration() {
			this.emailALert = {}

			axios.post(process.env.VUE_APP_BACKEND_URL + '/api/mailchimp/register/newsletter', {
				email: this.email,
				gdpr_consent: this.gdpr
			}).then(success => {
				this.emailAlert.text = 'Prihlasené k newsletteru'
				this.emailAlert.type = 'success'
				this.email = ''
			}).catch(error => {
				console.error(error.response)
				if (error.response.data) {
					let message = ''

					Object.values(error.response.data.error).forEach(text => {
						message += ` ${text}`
					})

					this.emailAlert.text = message

				} else {
					this.emailAlert.text = error.response.statusText
				}

				this.emailAlert.type = 'danger'
			})
		},
	},
}
</script>

<style lang="scss" scoped>
	.footer-wrapper {
		border-top: 1px solid rgba(0, 0, 0, 0.07);
	}

	.footer-content {
		max-width: 1120px;
		margin: auto;
		padding: 55px 0;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.newsletter-wrapper {
		h5 {
			font-size: 12px;
			font-weight: 900;
			line-height: 24px;
			color: #322D1E;
		}

		form {
			margin: 0 !important;
		}

		input {
			display: inline-block;
			height: 38px;
			width: calc(100% - 93px);
            background-color: gray;

			padding-left: 10px;
			border-style: none;
			background-color: transparent;
		}

		input:focus, input:active {
			outline: none;
		}

		input::placeholder {
			font-weight: 200;
			font-size: 14px;
			color: #434343;
		}

		button {
			display: inline-block;
			width: 93px;
			padding: 10px 18px;

			background-color: #E85A1F;
			border-radius: 4px;
			border-style: none;

			color: #ffffff;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
		}

		.input-button-wrapper {
			background-color: #F6F6F6;
			border-radius: 4px;
			padding: 5px;
		}
	}

	.contact-info-flex-wrapper {
		display: flex;
		justify-content: space-between;

		div:first-child {
			margin-right: 50px;
		}
	}

	.contact-info-wrapper {
		p, a {
			display: block;
			margin: 0;

			color: #322D1E;
			font-size: 13px;
			font-weight: 300;
			line-height: 27px;

			b {
				font-weight: 600;
				color: #000000;
			}
		}

		a {
			text-decoration: underline;
		}

		a:hover {
			cursor: pointer;
		}
	}

	.created-by-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		margin-top: 30px;

		p {
			color: #322D1E;
			margin: 0 5px 0 0;

			font-size: 12px;
			font-weight: 100;
			line-height: 14px;
		}

		img {
			height: 14px;
		}
	}

	.created-by-wrapper:hover {
		cursor: pointer;
	}

	@media only screen and (max-width: 1200px) {
		.footer-content {
			max-width: 90vw !important;
		}
	}

	@media only screen and (max-width: 730px) {
		.footer-content {
			display: block !important;

			> div {
				margin-top: 30px !important;
			}

			.newsletter-wrapper {
				input {
					width: calc(100% - 160px);
				}

				button {
					width: 150px;
				}
			}
		}

		.contact-info-flex-wrapper,
		.created-by-wrapper {
			justify-content: flex-start !important;
		}
	}

	@media only screen and (max-width: 500px) {
		.footer-content {
			.newsletter-wrapper {
				input,
				button {
					width: 100% !important;
				}
			}
		}
	}
</style>
